import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { BLACK, GRAY_03, WHITE } from '../../constants/colors';
import EmailServices from '../../services/EmailServices';
import { UrbaneFont } from '../../styles/common';
import { getDesktopStyle } from '../../styles/layout';
import { pxToRem } from '../../utils/common';
import ContactFormInput from './ContactFormInput';
import ContactFormTextarea from './ContactFormTextarea';

const initialState = {
	company: '',
	department: '',
	manager: '',
	tel: '',
	email: '',
	content: '',
	receiveMailAddr: '',
};

const ContactModal = (props: any) => {
	const [formInfo, setFormInfo] = useState(initialState);
	const [isOpen, setIsOpen] = useState(false);

	const handleOnChangeFormInfo = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setFormInfo((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
			receiveMailAddr: props.text.link,
		}));
	}, []);

	const handleOnClickChange = (e: { preventDefault: () => void }) => {
		postEmailData(formInfo);
		e.preventDefault();
	};

	const handleOnClickClose = (e: { preventDefault: () => void }) => {
		setIsOpen(false);
		props.parentFunction(isOpen);
		e.preventDefault();
	};

	const successCallback = useCallback(() => {
		alert('이메일이 전송되었습니다.');
		setIsOpen(false);
		props.parentFunction(isOpen);
	}, []);

	const errorCallback = useCallback((message?: string) => {
		alert(message);
	}, []);

	const postEmailData = useCallback(
		async (params) => {
			await EmailServices.processGetEmail({
				InputParams: params,
				successCallback,
				errorCallback,
			});
		},
		[errorCallback, successCallback],
	);

	const { company, department, manager, tel, email, content } = formInfo;

	return (
		<ContactModalWrapper>
			<ContactModalTitle>
				{props.text.title}
				<ContactModalDescription>{props.text.description}</ContactModalDescription>
			</ContactModalTitle>
			<ContactModalMail>{props.text.link}</ContactModalMail>
			<form>
				<ContactFormBox>
					<ContactFormInput
						label="회사명"
						type="text"
						name="company"
						value={company}
						onChange={handleOnChangeFormInfo}
						placeholder="소속된 회사이름을 입력해주세요."
					/>
					<ContactFormInput
						label="부서"
						type="text"
						name="department"
						value={department}
						onChange={handleOnChangeFormInfo}
						placeholder="소속된 부서명을 입력해주세요."
					/>
					<ContactFormInput
						label="담당자 (직책)"
						type="text"
						name="manager"
						value={manager}
						onChange={handleOnChangeFormInfo}
						placeholder="성함을 입력해주세요."
					/>
					<ContactFormInput
						label="연락처"
						type="text"
						name="tel"
						inputMode="numeric"
						value={tel}
						onChange={handleOnChangeFormInfo}
						placeholder="연락처를 입력해주세요."
					/>
					<ContactFormInput
						label="이메일"
						type="text"
						name="email"
						value={email}
						onChange={handleOnChangeFormInfo}
						placeholder="이메일을 입력해주세요."
					/>
					<ContactFormTextarea
						label="문의내용"
						name="content"
						value={content}
						onChange={handleOnChangeFormInfo}
						placeholder="자세한 내용을 입력해주세요."
					/>
				</ContactFormBox>
				<ContactFormButtonWrap>
					<ContactFormButtonSub onClick={handleOnClickClose}>취소</ContactFormButtonSub>
					<ContactFormButton onClick={handleOnClickChange}>보내기</ContactFormButton>
				</ContactFormButtonWrap>
			</form>
		</ContactModalWrapper>
	);
};

export default ContactModal;

const ContactModalWrapper = styled.div`
	padding: ${pxToRem(33)} ${pxToRem(30)};
	color: ${BLACK};

	${getDesktopStyle(css`
		padding: ${pxToRem(40)} ${pxToRem(52)};
	`)}
`;

const ContactModalTitle = styled.h2`
	${UrbaneFont};
	font-size: ${pxToRem(24)};
	font-weight: 700;
	text-align: center;

	${getDesktopStyle(css`
		font-size: ${pxToRem(28)};
		text-align: left;
	`)}
`;

const ContactModalDescription = styled.span`
	display: block;
	font-size: ${pxToRem(15)};

	${getDesktopStyle(css`
		display: inline;
		margin-left: ${pxToRem(20)};
		font-size: ${pxToRem(15)};
	`)}
`;

const ContactModalMail = styled.p`
	${UrbaneFont};
	font-weight: 600;
	margin-top: ${pxToRem(10)};
	text-decoration: none;
	font-size: ${pxToRem(14)};
	text-align: center;

	${getDesktopStyle(css`
		font-size: ${pxToRem(15)};
		text-decoration: underline;
		text-align: left;
	`)}
`;

const ContactFormBox = styled.div`
	margin-top: ${pxToRem(24)};

	${getDesktopStyle(css`
		display: flex;
		flex-flow: column wrap;
		width: 100%;
		height: ${pxToRem(330)};
		margin-top: ${pxToRem(45)};
	`)}
`;

const ContactFormButtonWrap = styled.div`
	margin-top: ${pxToRem(24)};
	display: flex;
	justify-content: space-between;

	${getDesktopStyle(css`
		width: 100%;
		margin-top: ${pxToRem(32)};
		display: block;
		text-align: center;
	`)}
`;

const contactFormButtonStyles = css`
	width: ${pxToRem(113)};
	height: ${pxToRem(36)};
	border-radius: ${pxToRem(4)};
	color: ${WHITE};
	background: ${BLACK};
	font-size: ${pxToRem(14)};

	${getDesktopStyle(css`
		width: ${pxToRem(154)};
		height: ${pxToRem(39)};
		font-size: ${pxToRem(16)};
	`)}
`;

const ContactFormButtonSub = styled.button`
	${contactFormButtonStyles};
	background: ${GRAY_03};
	font-size: ${pxToRem(14)};

	${getDesktopStyle(css`
		margin-right: ${pxToRem(31)};
	`)}
`;

const ContactFormButton = styled.button`
	${contactFormButtonStyles};

	background: ${BLACK};
	font-size: ${pxToRem(14)};
`;
